import { useContext, useEffect, useMemo, useState } from 'react';

import { CalcContext } from '..';
import { CalcTitleStyled } from '../styled';
import {
  USD_EUR,
  getA1Duty,
  getA2Duty,
  getA3Duty,
  getOurPrice,
  getSVH,
  getTax,
  getVehicle,
  getAuction,
  getSeaDelivery,
  getPlace,
  getFee,
  getIAAIFee,
} from '../utils';

import { OverviewContentStyled, OverviewStyled, ResultStyled } from './styled';
import { PortMap } from '@autrm/datas/port-map';

export function CalcResults() {
  const calcData = useContext(CalcContext);
  const [currentEURRate, setIsCurrentEURRate] = useState(0);
  const [currentUSDRate, setIsCurrentUSDRate] = useState(0);

  useEffect(() => {
    fetch(
      `https://api.nbrb.by/exrates/rates/978?parammode=1&ondate=${new Date().toISOString().slice(0, -13)}00:00:00`,
    )
      .then((response) => response.json())
      .then((data) => setIsCurrentEURRate(data?.Cur_OfficialRate))
      .catch((error) => console.error(error));

    fetch(
      `https://api.nbrb.by/exrates/rates/840?parammode=1&ondate=${new Date().toISOString().slice(0, -13)}00:00:00`,
    )
      .then((response) => response.json())
      .then((usdData) => setIsCurrentUSDRate(usdData?.Cur_OfficialRate))
      .catch((error) => console.error(error));
  }, []);

  const seasideDelivery = useMemo(() => {
    let price = 0;
    const auction = getAuction(calcData?.auction, calcData?.location);

    // if (calcData?.suvTypeOptionsActive) {
    //   // Suv
    //   if (calcData?.suvSelectedOption === 0) {
    //     additionalPrice = 100;
    //   }

    //   // or

    //   // Big SUV
    //   if (calcData?.suvSelectedOption === 1) {
    //     additionalPrice = 250;
    //   }
    // }

    // // + Electro
    // if (calcData?.electro) {
    //   additionalPrice = additionalPrice + 175;
    // }

    if (calcData?.vehicle === 'auto') {
      price = auction ? auction.car : 0;

      if (calcData?.autoSize === 'overSizeValue') {
        price = price + price * 0.2;
      }
    }

    if (calcData?.vehicle === 'moto') {
      price = auction ? auction.moto : 0;
    }

    return price;
  }, [calcData]);

  const seaDelivery = useMemo(() => {
    let additionalPrice = 0;
    const auction = getAuction(calcData?.auction, calcData?.location);
    const delivery = getSeaDelivery(
      auction?.delivery,
      calcData?.vehicle,
      calcData?.toPort || 1,
      calcData?.vehicle === 'auto' ? calcData?.autoSize || '' : calcData?.motoSize || '',
    );

    // let over = 0;
    // if (calcData?.suv || calcData?.bigSuv) over = 100;
    // if (calcData?.electro) over = 175;

    if (calcData?.suvTypeOptionsActive) {
      // Suv
      if (calcData?.suvSelectedOption === 0) {
        additionalPrice = 100;
      }

      // or

      // Big SUV
      if (calcData?.suvSelectedOption === 1) {
        additionalPrice = 250;
      }
    }

    // + Electro
    if (calcData?.electro) {
      additionalPrice = additionalPrice + 175;
    }

    return delivery + additionalPrice;
  }, [
    calcData?.auction,
    calcData?.autoSize,
    calcData?.electro,
    calcData?.location,
    calcData?.motoSize,
    calcData?.suvSelectedOption,
    calcData?.suvTypeOptionsActive,
    calcData?.toPort,
    calcData?.vehicle,
  ]);

  const [ourPrice] = useState(getOurPrice());

  const duty = useMemo(() => {
    const price = calcData?.price ? calcData?.price : 0;
    const volume = calcData?.volume ? calcData?.volume : 0;
    let d = 0;

    if (calcData?.vehicle === 'auto') {
      if (calcData?.age == 'a1') {
        d = getA1Duty(price, volume);
      } else if (calcData?.age == 'a2') {
        d = getA2Duty(volume);
      } else if (calcData?.age == 'a3') {
        d = getA3Duty(volume);
      }
      if (calcData?.benefit) {
        d = d / 2;
      }
      if (calcData?.electro && calcData?.electroOption == 1) {
        d = 0;
      }
    }

    // Стоимость растаможки на новый/подержанный мотоцикл составляет:

    // С объемом двигателя не более 50 см3 - пошлина 17,6% + НДС 20%
    // С объемом двигателя не более 50 см3 - пошлина 17,6% + НДС 20%
    // С объемом двигателя от 50 до 80 см3 - пошлина 17,6% + НДС 20%
    // С объемом двигателя от 125 до 250 см3 - пошлина 17,6% + НДС 20%
    // С объемом двигателя от 250 до 380 см3 - пошлина 18% + НДС 20%
    // С объемом двигателя от 380 до 500 см3 - пошлина 18% + НДС 20%
    // С объемом двигателя от 500 до 800 см3 - пошлина 18% + НДС 20%
    // С объемом двигателя более 800 см3 - пошлина 16% + НДС 20%

    if (calcData?.vehicle === 'moto') {
      if ((calcData?.volume || 0) <= 50) {
        d = price * (Number(0.176) + 0.2);
      }

      if ((calcData?.volume || 0) > 50 && (calcData?.volume || 0) < 80) {
        d = price * (Number(0.176) + 0.2);
      }

      if ((calcData?.volume || 0) > 125 && (calcData?.volume || 0) < 250) {
        d = price * (Number(0.176) + 0.2);
      }

      if ((calcData?.volume || 0) > 250 && (calcData?.volume || 0) < 380) {
        d = price * (Number(0.18) + 0.2);
      }

      if ((calcData?.volume || 0) >= 380 && (calcData?.volume || 0) < 500) {
        d = price * (Number(0.18) + 0.2);
      }

      if ((calcData?.volume || 0) >= 500 && (calcData?.volume || 0) < 800) {
        d = price * (Number(0.18) + 0.2);
      }

      if ((calcData?.volume || 0) >= 800) {
        d = price * (Number(0.16) + 0.2);
      }
    }

    return Math.round(d);
  }, [
    calcData?.price,
    calcData?.volume,
    calcData?.vehicle,
    calcData?.age,
    calcData?.benefit,
    calcData?.electro,
    calcData?.electroOption,
  ]);

  const tax = useMemo(() => {
    let res = getTax();
    if (calcData?.electro) res = 0;

    return res;
  }, [calcData?.electro]);

  const scrap = useMemo(() => {
    const v = getVehicle(calcData?.vehicle);
    if (!v) return 0;
    let scrap = 0;
    switch (calcData?.age) {
      case 'a1':
        scrap = v.scrap[0];
        break;
      case 'a2':
        scrap = v.scrap[1];
        break;
      case 'a3':
        scrap = v.scrap[2];
        break;
    }

    return scrap;
  }, [calcData?.vehicle, calcData?.age]);

  const [svh] = useState(getSVH());

  const result = useMemo(() => {
    let result = 0;
    result += calcData?.price ? calcData?.price : 0;
    result +=
      calcData?.auction === 'Copart' ? getFee(calcData?.price) : getIAAIFee(calcData?.price);
    result += seasideDelivery;
    result += seaDelivery;
    result += ourPrice / currentUSDRate;
    result += duty / USD_EUR;
    result += tax / currentUSDRate;
    result += Math.round(getSVH() / currentUSDRate);
    result += scrap / currentUSDRate;

    return Math.round(result);
  }, [
    calcData?.price,
    calcData?.auction,
    seasideDelivery,
    seaDelivery,
    ourPrice,
    currentUSDRate,
    duty,
    tax,
    scrap,
  ]);

  return (
    <div>
      <OverviewStyled>
        <div>
          <CalcTitleStyled>Покупка и доставка</CalcTitleStyled>
        </div>
        <OverviewContentStyled>
          <ul>
            <li>
              <span>Стоимость авто/мото</span>$ {calcData?.price}
            </li>
            <li>
              <span>Аукционный сбор</span>~ ${' '}
              {calcData?.auction === 'Copart'
                ? getFee(calcData?.price)
                : getIAAIFee(calcData?.price)}
            </li>
            <li className="clean-styles">
              <span>
                {/* @ts-ignore */}
                Транспортировка в {PortMap?.[calcData?.toPort]} через порт{' '}
                <strong>{getPlace(calcData?.location)?.delivery}</strong>
              </span>
              $ {seasideDelivery + seaDelivery}
            </li>
            <li className="clean-styles small-font">
              <div>по суше - $ {seasideDelivery}</div>
            </li>
            <li className="small-font">
              <div>по воде - $ {seaDelivery}</div>
            </li>
            {/* <li>
              <span>Доставка от порта до Клайпеды</span>$ {seaDelivery}
            </li> */}
            <li>
              <span>Стоимость услуг</span>
              {ourPrice} BYN
            </li>
          </ul>
        </OverviewContentStyled>
        <p
          style={{
            // width: '70%',
            color: 'red',
            fontWeight: 700,
            fontSize: '14px',
            padding: '10px 0',
          }}
        >
          * В расчет не входит стоимость доставки Клайпеда - Минск и комиссии за переводы.
        </p>
      </OverviewStyled>
      <OverviewStyled>
        <div>
          <CalcTitleStyled>Растаможка и оформление</CalcTitleStyled>
        </div>
        <OverviewContentStyled>
          <ul>
            <li>
              <span>Таможенная пошлина</span>€ {duty} | BYN {duty * currentEURRate}
            </li>
            <li>
              <span>Таможенный сбор</span>
              {tax} BYN
            </li>
            <li>
              <span>Утилизационный сбор</span>
              {scrap} BYN
            </li>
            <li>
              <span>Расходы на СВХ</span>~ {svh} BYN
            </li>
          </ul>
        </OverviewContentStyled>
      </OverviewStyled>
      <ResultStyled>
        <span>Итого:</span>$ {result}
      </ResultStyled>
    </div>
  );
}
