import { useContext, useState } from 'react';

import { ConfigProvider, Checkbox, Radio, Button, Divider, Select } from 'antd';

import { useWindowSize } from '@autrm/common/hooks/useWindowSize';
import { colorPrimary } from '@autrm/common/tokens/colors';

import { CalcContext } from '..';
import { CalcTitleStyled } from '../styled';

import { AuctionLocation } from './AuctionLocation';
import { Benefit } from './Benefit';
import { Electro } from './Electro';
import { EngineCapacity } from './EngineCapacity';
import { Price } from './Price';
// import { SUV } from './SUV';
import { VehicleSelect } from './VehicleSelect';
import { YearsRange } from './YearsRange';
import {
  FormWrapperStyled,
  HorizontalElementStyled,
  HorizontalWrapperStyled,
  ModalStyled,
  SUVTypeListStyled,
  TableStyled,
  TableWrapperStyled,
} from './styled';

export function CalcForm() {
  const calcContext = useContext(CalcContext);
  const [isModalShowed, setIsModalShowed] = useState(false);
  const { isMobileView } = useWindowSize();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary,
        },
      }}
    >
      <FormWrapperStyled>
        <CalcTitleStyled>Калькулятор стоимости</CalcTitleStyled>
        <VehicleSelect />
        <Price />
        <HorizontalWrapperStyled>
          <HorizontalElementStyled>
            <YearsRange />
          </HorizontalElementStyled>
          <HorizontalElementStyled>
            <EngineCapacity />
          </HorizontalElementStyled>
        </HorizontalWrapperStyled>
        <AuctionLocation />
        <div id="location-label">Место назначения</div>
        <Select
          id="auction_location_select"
          key={`auction_location_select`}
          defaultValue={calcContext?.toPort}
          options={[
            {
              value: 1,
              label: 'Литва',
            },
            {
              value: 2,
              label: 'Поти',
            },
            {
              value: 3,
              label: 'Актау-Минск',
            },
          ]}
          size="large"
          onChange={calcContext?.setToPort}
        />
        <br />
        {calcContext?.vehicle === 'auto' && (
          <>
            <div id="location-label">Размер авто</div>
            <Select
              id="auction_location_select"
              key={`auto_size_select`}
              defaultValue={calcContext?.autoSize}
              options={[
                {
                  value: 'regValue',
                  label: 'Regular',
                },
                {
                  value: 'lgValue',
                  label: 'Large',
                },
                {
                  value: 'xlgValue',
                  label: 'X-Large',
                },
                {
                  value: 'overSizeValue',
                  label: 'OverSize',
                },
              ]}
              size="large"
              onChange={calcContext?.setAutoSize}
            />
            <br />
          </>
        )}

        {calcContext?.vehicle === 'moto' && (
          <>
            <div id="location-label">Размер мото</div>
            <Select
              id="auction_location_select"
              key={`auto_size_select`}
              defaultValue={calcContext?.motoSize}
              options={[
                {
                  value: 'sport',
                  label: 'Sport',
                },
                {
                  value: 'lg',
                  label: 'Large',
                },
                {
                  value: 'atw',
                  label: 'ATW',
                },
              ]}
              size="large"
              onChange={calcContext?.setMotoSize}
            />
            <br />
          </>
        )}
        <Electro />
        {calcContext?.electro && (
          <SUVTypeListStyled>
            <Radio.Group
              onChange={(e) => calcContext?.setElectroOption(e.target.value)}
              value={calcContext?.electroOption}
            >
              <Radio value={1}>Электромобиль</Radio>
              <Radio value={2}>Гибрид</Radio>
            </Radio.Group>
          </SUVTypeListStyled>
        )}
        {/* <SUV /> */}
        <Benefit />
        <Checkbox
          checked={calcContext?.suvTypeOptionsActive}
          onChange={(e) => calcContext?.setSuvTypeOptionsActive(e.target.checked)}
        >
          SUV (Стандартный/Большой паркетник)
        </Checkbox>
        {calcContext?.suvTypeOptionsActive && (
          <SUVTypeListStyled>
            <Radio.Group
              onChange={(e) => calcContext?.setSuvSelectedOption(e.target.value)}
              value={calcContext?.suvSelectedOption}
            >
              <Radio value={0}>Стандартный паркетник</Radio>
              <Radio value={1}>Большой паркетник</Radio>
            </Radio.Group>
          </SUVTypeListStyled>
        )}
        <Divider
          orientation="left"
          plain
        />
        <Button
          type="primary"
          size="middle"
          onClick={() => setIsModalShowed(!isModalShowed)}
        >
          Марки и модели
        </Button>
        <ModalStyled
          title="Марки и модели"
          open={isModalShowed}
          centered={false}
          width={isMobileView ? 350 : 700}
          footer={false}
          onCancel={() => setIsModalShowed(!isModalShowed)}
        >
          <Divider
            orientation="left"
            plain
            style={{
              fontWeight: 700,
              color: colorPrimary,
            }}
          >
            Легенда
          </Divider>
          <TableWrapperStyled>
            <TableStyled>
              <tbody>
                <tr>
                  <td>Small</td>
                  <td>Sedan</td>
                  <td>Седан, купе, маленький хэтч</td>
                </tr>
                <tr>
                  <td>Large</td>
                  <td>Large</td>
                  <td>
                    Компактные кроссоверы Б (Encore) и С (Equinox) класса, седаны премиум-класса
                  </td>
                </tr>
                <tr>
                  <td>X Large</td>
                  <td>X Large</td>
                  <td>
                    Большой кросс / внедорожник / минивэн / малый пикап (до 5 м) / Merсedes Metris
                  </td>
                </tr>
                <tr>
                  <td>XX Large </td>
                  <td>Oversize</td>
                  <td>Пикап на двойной кабине с бортом / пикап на лифте / некоторые ВЭНы</td>
                </tr>
              </tbody>
            </TableStyled>
          </TableWrapperStyled>
          <Divider
            orientation="left"
            plain
            style={{
              fontWeight: 700,
              color: colorPrimary,
            }}
          >
            Таблица марок и моделей
          </Divider>
          <TableWrapperStyled>
            <TableStyled>
              <tbody>
                <tr>
                  <td>ACURA</td>
                  <td>ILX</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>ACURA</td>
                  <td>INTEGRA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>ACURA</td>
                  <td>MDX</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>ACURA</td>
                  <td>RDX</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>ACURA</td>
                  <td>RLX</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>ACURA</td>
                  <td>TLX</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>ALFA ROMEO</td>
                  <td>4C SPIDER</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>ALFA ROMEO</td>
                  <td>GIULIA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>ALFA ROMEO</td>
                  <td>STELVIO</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>A3</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>A4</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>A5</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>A6</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>A7</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>A8</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>A8</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>ALLROAD</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>E-TRON</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>Q3</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>Q4</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>Q5</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>Q7</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>Q8</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>S3</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>S4</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>S5</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>AUDI</td>
                  <td>TT</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>1 SERIES</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>2 SERIES</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>3 SERIES</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>4 SERIES</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>5 SERIES</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>6 SERIES</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>7 SERIES</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>8 SERIES</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>I3</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>I8</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>IX</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>X1</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>X3</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>X4</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>X5</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>X6</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>X7</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>BMW</td>
                  <td>Z4</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>BUICK</td>
                  <td>CASCADA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>BUICK</td>
                  <td>ENCLAVE</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>BUICK</td>
                  <td>ENCORE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>BUICK</td>
                  <td>ENVISION</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>BUICK</td>
                  <td>LACROSSE</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>BUICK</td>
                  <td>REGAL</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>BUICK</td>
                  <td>VERANO</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CADILLAC</td>
                  <td>ATS</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CADILLAC</td>
                  <td>CT4</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CADILLAC</td>
                  <td>CT6</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CADILLAC</td>
                  <td>CTS</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CADILLAC</td>
                  <td>ELR</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CADILLAC</td>
                  <td>ESCALADE</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>CADILLAC</td>
                  <td>ESCALADE ESV</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>CADILLAC</td>
                  <td>LYRIQ</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>CADILLAC</td>
                  <td>XT4</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>CADILLAC</td>
                  <td>XT5</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>CADILLAC</td>
                  <td>XT6</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>BLAZER</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>BOLT</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>CAMARO</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>CORVETTE</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>CRUZE</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>EQUINOX</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>MALIBU</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>SILVERADO</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>SPARK</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>TAHOE </td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>TRAILBLAZER</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>TRAVERSE</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>TRAX</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>CHEVROLET</td>
                  <td>VOLT</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CHRYSLER</td>
                  <td>200</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>CHRYSLER</td>
                  <td>300</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>CHRYSLER</td>
                  <td>PACIFICA</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>CHRYSLER</td>
                  <td>TOWN &amp; COUNTRY TOURING</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>CHRYSLER</td>
                  <td>VOYAGER</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>DODGE</td>
                  <td>AVENGER</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>DODGE</td>
                  <td>CHALLENGER</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>DODGE</td>
                  <td>CHARGER</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>DODGE</td>
                  <td>DART</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>DODGE</td>
                  <td>GRAND CARAVAN</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>DODGE</td>
                  <td>JOURNEY</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>FIAT</td>
                  <td>500</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>FIAT</td>
                  <td>500L</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>FIAT</td>
                  <td>124 SPIDER</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>FIAT</td>
                  <td>500X</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>BRONCO</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>BRONCO RAPTOR</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>C-MAX</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>ECOSPORT</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>EDGE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>ESCAPE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>EXPEDITION</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>EXPEDITION MAX</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>EXPLORER</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>F-150 (250 / 350)</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>FIESTA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>FLEX</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>FOCUS</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>FUSION</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>MAVERICK</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>MUSTANG</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>MUSTANG MACH-E</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>RANGER</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>FORD</td>
                  <td>TRANSIT CONNECT</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>GENESIS</td>
                  <td>G70</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>GENESIS</td>
                  <td>G90</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>GENESIS</td>
                  <td>GV70</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>GENESIS</td>
                  <td>GV80</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>GMC</td>
                  <td>ACADIA</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>GMC</td>
                  <td>CANYON</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>GMC</td>
                  <td>SIERRA</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>GMC</td>
                  <td>TERRAIN</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>HONDA</td>
                  <td>ACCORD</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>HONDA</td>
                  <td>CIVIC</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>HONDA</td>
                  <td>CLARITY</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>HONDA</td>
                  <td>CR-V</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>HONDA</td>
                  <td>FIT</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>HONDA</td>
                  <td>HR-V</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>HONDA</td>
                  <td>INSIGHT</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>HONDA</td>
                  <td>ODYSSEY</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>HONDA</td>
                  <td>PILOT</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>HONDA</td>
                  <td>S2000</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>HONDA</td>
                  <td>RIDGELINE</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>HYUNDAI</td>
                  <td>ACCENT</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>HYUNDAI</td>
                  <td>ELANTRA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>HYUNDAI</td>
                  <td>IONIQ</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>HYUNDAI</td>
                  <td>KONA</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>HYUNDAI</td>
                  <td>PALISADE</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>HYUNDAI</td>
                  <td>SONATA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>HYUNDAI</td>
                  <td>TUCSON</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>HYUNDAI</td>
                  <td>VELOSTER</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>HYUNDAI</td>
                  <td>VENUE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>INFINITI</td>
                  <td>Q50</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>INFINITI</td>
                  <td>Q60</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>INFINITI</td>
                  <td>QX30</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>INFINITI</td>
                  <td>QX55</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>INFINITI</td>
                  <td>QX50</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>INFINITI</td>
                  <td>QX60</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>JAGUAR</td>
                  <td>E-PACE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>JAGUAR</td>
                  <td>F-PACE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>JAGUAR</td>
                  <td>F-TYPE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>JAGUAR</td>
                  <td>I-PACE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>JAGUAR</td>
                  <td>XE</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>JAGUAR</td>
                  <td>XF</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>JAGUAR</td>
                  <td>XJ</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>JEEP</td>
                  <td>CHEROKEE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>JEEP</td>
                  <td>COMPASS</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>JEEP</td>
                  <td>GRAND CHEROKEE</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>JEEP</td>
                  <td>PATRIOT</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>JEEP</td>
                  <td>RENEGADE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>JEEP</td>
                  <td>WRANGLER (3 door)</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>JEEP</td>
                  <td>WRANGLER (5 door)</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>KIA</td>
                  <td>FORTE</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>KIA</td>
                  <td>K5</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>KIA</td>
                  <td>NIRO</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>KIA</td>
                  <td>OPTIMA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>KIA</td>
                  <td>RIO</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>KIA</td>
                  <td>SEDONA</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>KIA</td>
                  <td>SELTOS</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>KIA</td>
                  <td>SORENTO</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>KIA</td>
                  <td>SOUL</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>KIA</td>
                  <td>SPORTAGE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>KIA</td>
                  <td>STINGER</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>LAND ROVER</td>
                  <td>DISCOVERY</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>LAND ROVER</td>
                  <td>DISCOVERY SPORT</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>LAND ROVER</td>
                  <td>DEFENDER </td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>LAND ROVER</td>
                  <td>RANGE ROVER</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>LAND ROVER</td>
                  <td>RANGE ROVER EVOQUE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>LAND ROVER</td>
                  <td>RANGE ROVER SPORT</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>LAND ROVER</td>
                  <td>RANGE ROVER VELAR</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>LEXUS</td>
                  <td>CT</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>LEXUS</td>
                  <td>ES</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>LEXUS</td>
                  <td>GS</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>LEXUS</td>
                  <td>GX</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>LEXUS</td>
                  <td>IS</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>LEXUS</td>
                  <td>NX</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>LEXUS</td>
                  <td>RC</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>LEXUS</td>
                  <td>RX</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>LEXUS</td>
                  <td>RZ</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>LEXUS</td>
                  <td>UX</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>LINCOLN</td>
                  <td>AVIATOR</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>LINCOLN</td>
                  <td>CONTINENTAL</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>LINCOLN</td>
                  <td>CORSAIR</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>LINCOLN</td>
                  <td>MKC</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>LINCOLN</td>
                  <td>MKZ</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>LINCOLN</td>
                  <td>NAUTILUS</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>LINCOLN</td>
                  <td>NAVIGATOR L</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>MASERATI</td>
                  <td>GHIBLI</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MASERATI</td>
                  <td>LEVANTE</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>MASERATI</td>
                  <td>QUATTROPORTE </td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MAZDA</td>
                  <td>CX-3</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MAZDA</td>
                  <td>CX-30</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MAZDA</td>
                  <td>CX-5</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MAZDA</td>
                  <td>CX-50</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MAZDA</td>
                  <td>CX-9</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>MAZDA</td>
                  <td>CX-90</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>MAZDA</td>
                  <td>3</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>MAZDA</td>
                  <td>6</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>MAZDA</td>
                  <td>MX-5 MIATA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>AMG GT</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>B-CLASS</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>C-CLASS</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>CLA-CLASS</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>CLS-CLASS</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>E-CLASS</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>G-CLASS</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>GLA-CLASS</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>GLB-CLASS</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>GLC-CLASS</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>GL-CLASS</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>GLE-CLASS</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>GLS-CLASS</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>METRIS</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>S-CLASS</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>MERCEDES-BENZ</td>
                  <td>SL-CLASS</td>
                  <td>large</td>
                </tr>
                <tr>
                  <td>MINI</td>
                  <td>CLUBMAN</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>MINI</td>
                  <td>COOPER</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>MINI</td>
                  <td>COOPER COUNTRYMAN</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MITSUBISHI</td>
                  <td>ECLIPSE</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>MITSUBISHI</td>
                  <td>ECLIPSE CROSS</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MITSUBISHI</td>
                  <td>I-MIEV</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>MITSUBISHI</td>
                  <td>MIRAGE</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>MITSUBISHI</td>
                  <td>OUTLANDER Sport</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>MITSUBISHI</td>
                  <td>OUTLANDER</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>NISSAN</td>
                  <td>370Z</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>NISSAN</td>
                  <td>KICKS</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>NISSAN</td>
                  <td>LEAF</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>NISSAN</td>
                  <td>MURANO</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>NISSAN</td>
                  <td>PATHFINDER</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>NISSAN</td>
                  <td>ROGUE</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>NISSAN</td>
                  <td>ROGUE Sport</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>NISSAN</td>
                  <td>SENTRA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>NISSAN</td>
                  <td>TITAN</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>NISSAN</td>
                  <td>VERSA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>NISSAN</td>
                  <td>VERSA NOTE</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>POLESTAR</td>
                  <td>POLESTAR 1</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>POLESTAR</td>
                  <td>POLESTAR 2</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>PORSCHE</td>
                  <td>911</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>PORSCHE</td>
                  <td>718 BOXSTER</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>PORSCHE</td>
                  <td>718 CAYMAN</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>PORSCHE</td>
                  <td>CAYENNE</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>PORSCHE</td>
                  <td>MACAN</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>PORSCHE</td>
                  <td>PANAMERA</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>PORSCHE</td>
                  <td>TAYCAN</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>RAM</td>
                  <td>1500</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>RAM</td>
                  <td>2500</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>SCION</td>
                  <td>IA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>SMART</td>
                  <td>FORTWO</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>SUBARU</td>
                  <td>ASCENT</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>SUBARU</td>
                  <td>BRZ</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>SUBARU</td>
                  <td>CROSSTREK</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>SUBARU</td>
                  <td>FORESTER</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>SUBARU</td>
                  <td>IMPREZA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>SUBARU</td>
                  <td>LEGACY</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>SUBARU</td>
                  <td>OUTBACK</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>SUBARU</td>
                  <td>WRX</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>TESLA</td>
                  <td>MODEL 3</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>TESLA</td>
                  <td>MODEL S</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>TESLA</td>
                  <td>MODEL X</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>TESLA</td>
                  <td>MODEL Y</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>86</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>CAMRY</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>C-HR</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>COROLLA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>GR SUPRA</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>HIGHLANDER</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>PRIUS</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>PRIUS C</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>PRIUS PRIME</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>PRIUS V</td>
                  <td>large</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>RAV4</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>SCION</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>SEQUOIA</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>SIENNA</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>TACOMA</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>TUNDRA</td>
                  <td>Oversize</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>VENZA</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>TOYOTA</td>
                  <td>YARIS</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>ARTEON</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>ATLAS</td>
                  <td>X Large</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>BEETLE</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>CC</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>GOLF</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>GOLF ALLTRACK</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>GTI</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>ID.4</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>JETTA</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>PASSAT</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>TAOS</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>VOLKSWAGEN</td>
                  <td>TIGUAN</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>VOLVO</td>
                  <td>C70</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLVO</td>
                  <td>S60</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLVO</td>
                  <td>S90</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>VOLVO</td>
                  <td>V50</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLVO</td>
                  <td>V60</td>
                  <td>Sedan</td>
                </tr>
                <tr>
                  <td>VOLVO</td>
                  <td>V90</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>VOLVO</td>
                  <td>XC40</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>VOLVO</td>
                  <td>XC60</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>VOLVO</td>
                  <td>XC70</td>
                  <td>Large</td>
                </tr>
                <tr>
                  <td>VOLVO</td>
                  <td>XC90</td>
                  <td>X Large</td>
                </tr>
              </tbody>
            </TableStyled>
          </TableWrapperStyled>
        </ModalStyled>
      </FormWrapperStyled>
    </ConfigProvider>
  );
}
